export const buildQueryString = (params) => {
  return `?${Object.keys(params).map(key => key + "=" + params[key]).join("&")}`;
};

export const capitalize = (value) => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const toDollars = (amountInCents) => {
  if (typeof amountInCents !== "number") {
    return amountInCents;
  }
  return amountInCents.toFixed(2)/100;
};

export const toCurrency = (amountInDollars) => {
  if (typeof amountInDollars !== "number") {
    return amountInDollars;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  });
  return formatter.format(amountInDollars);
};

export const truncate = (text, stop, clamp) => {
  return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
};

export const zeroToFree = (value) => {
  return value === "$0.00" ? "free" : value;
};

export const escapeKeyPressed = (event) => {
  const key = event.key || event.keyCode;

  return key === "Escape" || key === "Esc" || key === 27;
};

export const enterKeyPressed = (event) => {
  const key = event.key || event.keyCode;

  return key === "Enter" || key === 13;
};

export const spacebarKeyPressed = (event) => {
  return event.code === "Space" || event.key === " " || event.keyCode === 32;
};

export const isStringOfNumbers = (str) => /^[0-9]+$/.test(str);

export const isIphone = () => {
  return /iPhone/.test(navigator.userAgent) && !window.MSStream;
};

export const isAndroid = () => {
  return /Android/.test(navigator.userAgent) && !window.MSStream;
};

export const appUrl = () => {
  if (isIphone()) {
    return "https://apps.apple.com/us/app/bark-barkbox-super-chewer/id570764065?ls=1";
  }
};

export const isTouchDevice = () => {
  return ("ontouchstart" in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
};

export const isMobileWidth = () => {
  return window.innerWidth <= 480;
};

export const pageProductLine = () => {
  const pathname = window.location && window.location.pathname;
  if (pathname.includes("super-chewer")) {
    return "Super Chewer";
  } else if (pathname.includes("brightdental")) {
    return "Bright";
  } else {
    return "Classic";
  }
};

export const isInViewport = (el, percentage = 100) => {
  const rect = el.getBoundingClientRect();
  const distanceFromBottomOfViewport = document.documentElement.clientHeight - (percentage / 100);

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const titleize = (phrase) => {
  return phrase.split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
