import Vue from "Instances/BarkVue";
import { pageProductLine } from "../../../javascript/utils/general";

let productBlockAmplitude = {
  trackEvent: () => { }
};

if (window.AmplitudeTracking) {
  productBlockAmplitude = AmplitudeTracking(window);
}

const ProductBlock = {
  name: "product-block",

  data() {
    return {};
  },


  methods: {
    handleClick(cardName, cardOrder) {
      const eventName = `${pageProductLine()} Homepage Contentful Product Card Click`;
      const pageName = window.document.title;
      const props = {
        "Page Name": pageName,
        "Card Name": cardName,
        "Card Order": cardOrder
      };
      
      productBlockAmplitude.trackEvent(eventName, props, true);
    }
  }
};

export default ProductBlock;
