export default {
  data() {
    return {
      chatAvailable: false,
      unreadMessageCount: 0,
      chatPaneOpen: false
    };
  },

  mounted() {
    const unreadMessageCookieValue = getCookie("unread_message_count");
    this.unreadMessageCount = unreadMessageCookieValue ? parseInt(unreadMessageCookieValue) : 0;
    this.chatPaneOpen = getCookie("chat_open") === "1";

    document.addEventListener("chat-widget-toggled", this.handleChatToggle);
    document.addEventListener("chat-message-received", this.handleChatMessageReceived);
    document.addEventListener("chat-availability-change", this.handleChatAvailabilityChange);
  },

  beforeDestroy() {
    document.removeEventListener("chat-widget-toggled", this.handleChatToggle);
    document.removeEventListener("chat-message-received", this.handleChatMessageReceived);
    document.removeEventListener("chat-availability-change", this.handleChatAvailabilityChange);
  },

  methods: {
    handleChatToggle(e) {
      const open = e.detail.open;
      if (open) {
        this.unreadMessageCount = 0;
      }
      this.chatPaneOpen = open;
    },

    handleChatMessageReceived() {
      if (!this.chatPaneOpen) {
        this.unreadMessageCount += 1;
      }
    },

    handleChatAvailabilityChange(e) {
      this.chatAvailable = e.detail.available;
    }
  }
};