// Set a minimum width for the "content-carousel-slide" class in your stylesheet.

<template>
  <div
    v-bind:id="carouselId"
    v-touch:swipe.left="moveRight"
    v-touch:swipe.right="moveLeft"
    class="content-carousel"
    v-bind:style="{ 'margin-left': -leftOffset, 'margin-right': -rightOffset }">
    <ul
      tabindex="0"
      v-bind:class="animationClass"
      class="content-carousel-list">
      <!-- This li positions the first slotted slide to render in its proper place,
      while allowing subsequent slides to "peek" on the left. -->
      <li
        aria-hidden="true"
        class="offset"
        v-bind:style="{ 'min-width': leftOffset }" />
      <slot name="content-carousel-list" />
    </ul>
    <nav class="nav-buttons flex-container flex--center">
      <button
        v-bind:disabled="!isLeftMoveable"
        v-bind:class="{ 'show-hover': !isTouchDevice }"
        class="carousel-component-left-arrow mr24"
        aria-label="move carousel back"
        v-on:click="move(-1)">
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 8.591 15.061"
          height="14px">
          <path
            d="M8.061,14.53l-7-7,7-7"
            fill="none"
            stroke-width="1.5px" />
        </svg>
      </button>
      <p
        v-if="!hideCounter"
        class="universal-color-neutral-coal">
        {{ slidesDisplayed }}
      </p>
      <button
        v-bind:disabled="!isRightMoveable"
        v-bind:class="{ 'show-hover': !isTouchDevice }"
        class="carousel-component-right-arrow ml24"
        aria-label="move carousel forward"
        v-on:click="move(1)">
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 8.591 15.061"
          height="14px">
          <path
            d="M.53,14.53l7-7-7-7"
            fill="none"
            stroke-width="1.5px" />
        </svg>
      </button>
    </nav>
  </div>
</template>

<script>

import Vue from "Instances/BarkVue";
import $ from "jquery";
import Vue2TouchEvents from "vue2-touch-events";
// TODO: decouple barkbox-rails dependency
import { isTouchDevice } from "../../../javascript/utils/general";
let contentCarouselAmplitude = { trackEvent: () => {} };

if (window.AmplitudeTracking) {
  contentCarouselAmplitude = AmplitudeTracking(window);
}

Vue.use(Vue2TouchEvents, {
  swipeTolerance: 30
});

export default {

  props: {
    carouselId: {
      type: String,
      required: true
    },
    hideCounter: {
      type: Boolean,
      required: true
    },
    slideCount: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      activeIndex: 0,
      isLeftMoveable: false,
      isRightMoveable: this.slideCount > 0,
      leftOffset: 0,
      positions: [],
      rightOffset: 0,
      slidesInView: 0,
      stepCount: 0
    };
  },

  computed: {
    animationClass() {
      return `${this.carouselId}-animation`;
    },

    isTouchDevice,

    slidesDisplayed() {
      if (this.slidesInView > 1) {
        return `${this.activeIndex + 1}-${this.activeIndex + this.slidesInView} of ${this.slideCount}`;
      } else {
        return `${this.activeIndex + 1} of ${this.slideCount}`;
      }
    }
  },

  mounted() {
    this.setCarouselData();
  },

  methods: {
    animateCarousel() {
      $(`.${this.animationClass}`).animate({ scrollLeft: this.positions[this.activeIndex]}, "slow");
    },

    move(direction) {
      this.trackNavClick(direction);

      if (direction > 0) {
        if (!this.isRightMoveable) return;
      } else {
        if (!this.isLeftMoveable) return;
      }

      this.activeIndex = this.activeIndex + direction;
      this.isRightMoveable = this.activeIndex < this.stepCount;
      this.isLeftMoveable = this.activeIndex > 0;
      this.animateCarousel();
    },

    moveRight() {
      this.move(1);
    },

    moveLeft() {
      this.move(-1);
    },

    setCarouselData() {
      const container = document.getElementById(this.carouselId);
      const slides = Array.from(container.querySelectorAll(".content-carousel-slide"));
      const containerBoundaries = container.getBoundingClientRect();
      const parentBoundaries = container.parentElement.getBoundingClientRect();

      this.leftOffset = containerBoundaries.left;
      this.rightOffset = window.innerWidth - containerBoundaries.right;

      this.positions = slides.map((slide, i) => {
        const slideDimensions = slide.getBoundingClientRect();

        if (containerBoundaries.right + this.rightOffset < slideDimensions.right) {
          this.stepCount++;
        }

        if (slideDimensions.left >= containerBoundaries.left && slideDimensions.right <= containerBoundaries.right + this.rightOffset) {
          this.slidesInView++;
        }

        return slideDimensions.left - this.leftOffset;
      });
    },

    trackNavClick(direction) {
      const eventName = "Content Carousel Nav Clicked";
      const props = {
        "carousel id" : this.carouselId,
        "direction" : direction > 0 ? "right" : "left",
        "slides in view": this.slidesDisplayed
      };

      contentCarouselAmplitude.trackEvent(eventName, props);
    }
  }
};
</script>
