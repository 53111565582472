import Vue from "Instances/BarkVue";
import Chat from "../../../javascript/components/Chat.vue";

let barkButtonAmplitude = {
  trackEvent: () => { }
};

if (window.AmplitudeTracking) {
  barkButtonAmplitude = AmplitudeTracking(window);
}

const BarkButton = {
  props: {
    containerIdentifier: {
      type: String,
      required: false,
      default: ""
    }
  },

  components: {
    Chat
  },

  methods: {
    chatButtonTrackingProps() {
      return {
        "Chat Provider": window.chat_provider,
        "Section Number": this.sectionId(),
        "Section": this.containerIdentifier
      };
    },

    handleClick(e) {
      barkButtonAmplitude.trackEvent("CTA Clicked", {
        "Section": this.containerIdentifier,
        "Section Number": this.sectionId(),
        "CTA Text": e.target.text
      });
    },

    sectionId() {
      const closestSection = this.$el.closest("[id^='section-']");
      return closestSection && closestSection.getAttribute("id");
    }
  }
};

export default BarkButton;
