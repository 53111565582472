<script>
/**
  @class Chat
  @description component responsible for rendering chat UI and listening
  to availability of Gladly chat

  This component was originally made for non Bark UI pages (pages that are built on "v3" & "v4" CSS),
  but is also in use on Bark UI pages as well, while non Bark UI pages are being deprecated.

  The stylesheets for this component are:
  - For non Bark UI pages: app/assets/stylesheets/v4/components/chat.scss
  - For Bark UI pages: app/assets/stylesheets/bark_ui/shared/chat.scss
 */

import ClickTracker from "./ClickTracker.vue";
import chatMixin from "../mixins/chatMixin";
import { enterKeyPressed, spacebarKeyPressed } from "../utils/general";

const CHAT_SVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 401.99 328.889" class="chat-bubble-svg" aria-labelledby="chat-bubble-title">
  <title id="chat-bubble-title"> Get help </title>
  <path d="M71.377,324.32a240.083,240.083,0,0,0,79.372-36.545A286.714,286.714,0,0,0,201,292.343q54.535.019,100.931-19.542t73.23-53.248q26.832-33.687,26.833-73.372T375.15,72.805q-26.832-33.689-73.228-53.249T200.994,0Q146.463,0,100.067,19.556T26.838,72.805Q0,106.5,0,146.182q0,33.972,20.28,63.96,20.268,29.971,55.671,50.531Q73.1,267.531,70.1,273.239A72.375,72.375,0,0,1,62.96,284.23q-4.14,5.287-6.423,8.275t-7.425,8.422s-6.354,6.847-9.2,12.141c-3.221,5.984-1.771,11.263.207,13.542s3.668,2.277,5.852,2.279C54.765,328.889,71.377,324.32,71.377,324.32Z" />
</svg>`;

export default {
  mixins: [chatMixin],

  props: {
    eventTrackName: String,
    getTrackingProps: {
      type: Function,
      default: () => {
        return { "Chat Provider": window.chat_provider };
      }
    },
    as: String,
    href: String,
    className: String,
    hideDisabledState: Boolean,
    useContactUsFallback: Boolean,
    unavailableScreenReaderText: String,
    disableBubble: Boolean,
    mobileMenuExpanded: Boolean,
    chatTag: String,
    prefillFields: Array
  },

  data() {
    return {
      chatProvider: window.chat_provider,
      shownOnMount: false
    };
  },

  computed: {
    screenReaderText() {
      return this.chatAvailable
        ? "Live chat"
        : this.unavailableScreenReaderText || "Chat is currently unavailable";
    },

    hasShowChatParam() {
      return window.location.search.includes("show_chat=true");
    },

    isDisabled() {
      return this.disabledClass() === "disabled";
    }
  },

  mounted() {
    document.addEventListener("chat-availability-change", (e) => {
      const available = e.detail.available;

      if (available && this.hasShowChatParam && !this.shownOnMount) {
        this.setShownOnMount();
      }
    });

    document.addEventListener("chat-message-received", () => {
      if (window.location.pathname.match(/.+\/cancel\/.+/) && !this.chatPaneOpen) {
        this.openPane();
      }
    });
  },

  methods: {
    toggleChatPane(e) {
      if (!this.chatPaneOpen) {
        this.openPane();
      }
    },

    handleChatKeyPress(e) {
      if (enterKeyPressed(e) || spacebarKeyPressed(e)) {
        e.preventDefault();
        this.toggleChatPane(e);
      }
    },

    displayBubble() {
      return this.as === "a" && this.disableBubble !== true;
    },

    openPane() {
      Gladly?.show();
    },

    disabledClass() {
      if (!this.chatAvailable) {
        return "disabled";
      } else {
        return "";
      }
    },

    indicatorClass() {
      if (
        this.chatAvailable &&
        this.displayBubble() &&
        (this.unreadMessageCount === 0 || this.chatPaneOpen)
      ) {
        return "chat-state-indicator";
      } else if (
        this.displayBubble() &&
        this.unreadMessageCount > 0 &&
        !this.chatPaneOpen &&
        !this.mobileMenuExpanded
      ) {
        return "chat-state-indicator unread-message";
      } else if (
        this.displayBubble() &&
        this.mobileMenuExpanded &&
        this.unreadMessageCount > 0 &&
        !this.chatPaneOpen
      ) {
        return "unread-message";
      }
    },

    clickTrackerClass() {
      if (!this.displayBubble()) {
        return "display block";
      } else if (this.mobileMenuExpanded && this.unreadMessageCount > 0) {
        return "chat-container";
      } else {
        return "";
      }
    },

    setShownOnMount() {
      this.shownOnMount = true;
      this.openPane();
    },

    brandContactUsHref() {
      if (window.location.pathname.includes("brightdental")) {
        return "/brightdental/contact-us";
      } else if (window.location.pathname.includes("super-chewer")) {
        return "/super-chewer/contact-us";
      } else {
        return "/contact-us";
      }
    }
  },

  /**
   * https://vuejs.org/v2/guide/render-function.html
   */
  render(createElement) {
    if (this.useContactUsFallback && !this.chatAvailable) {
      return createElement(
        ClickTracker,
        {
          props: {
            as: "a",
            eventName: "Homepage Body Contact Us Clicked",
            href: this.brandContactUsHref(),
            className: this.className
          }
        },
        "Contact us"
      );
    } else {
      return createElement(
        this.as,
        {
          attrs: {
            href: this.chatAvailable ? null : this.href,
            // When chat is available, the href is absent on the <a> tag, so we add tabIndex="0" to make it focusable
            tabIndex: this.chatAvailable && this.as === "a" ? "0" : null,
            // When no href is provided, the <a> tag is still announced by screen readers-- so we hide it
            "aria-hidden": !this.chatAvailable && this.as === "a" && !this.href,
            disabled: this.as === "button" && this.isDisabled
          },
          class: [this.className, this.disabledClass()],
          on: {
            click: this.toggleChatPane,
            "keydown": this.handleChatKeyPress
          }
        },
        [
          createElement(
            ClickTracker,
            {
              props: {
                as: "span",
                // prevent amplitude logging by passing an empty string
                // to clicktracker as event name if chat is unavailable

                // Tracking event WILL come through if chat is unavailable,
                // but hideDisabledState is set to true
                eventName:
                  this.chatAvailable || this.hideDisabledState
                    ? this.eventTrackName
                    : "",
                getTrackingProps: this.getTrackingProps,
                className: `chat-link-inner ${this.clickTrackerClass()}`
              }
            },
            [
              createElement("span", {
                domProps: {
                  innerHTML: this.displayBubble() ? "Get help " : ""
                }
              }),
              createElement(
                "span",
                {
                  class: [
                    this.displayBubble() &&
                    (!this.mobileMenuExpanded || this.unreadMessageCount === 0)
                      ? "chat-bubble"
                      : "hide-chat-bubble"
                  ]
                },
                [
                  createElement("span", {
                    domProps: {
                      innerHTML:
                        this.displayBubble() &&
                        (!this.mobileMenuExpanded ||
                          this.unreadMessageCount === 0)
                          ? CHAT_SVG
                          : ""
                    }
                  }),
                  createElement("span", {
                    class: [this.indicatorClass()],
                    domProps: {
                      innerHTML:
                        this.displayBubble() &&
                        this.unreadMessageCount > 0 &&
                        !this.chatPaneOpen
                          ? this.unreadMessageCount
                          : ""
                    }
                  })
                ]
              ),
              this.$slots.default
            ]
          ),
          createElement("div", { class: ["sr-only"] }, [
            createElement("p", this.screenReaderText)
          ])
        ]
      );
    }
  }
};
</script>
