import Vue from "Instances/BarkVue";
import BarkButton from "CMS/button_component/BarkButton";
import ProductBlock from "CMS/product_block_component/ProductBlock";
import ContentBlockCarousel from "BarkUI/content_block_carousel_component/ContentBlockCarousel.vue";

const Hero = {
  components: {
    BarkButton,
    ProductBlock,
    ContentBlockCarousel
  },
  props: {
    bleedImages: {
      type: Array,
      default: () => []
    },
    bleedBackgroundRotationInSeconds: {
      type: Number
    },
    isFullBleedMobileHero: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageIndex: 0,
      preloadedImages: [],
      isSlideshowActive: true,
      activeSlideshowInterval: undefined,
      controlsAltText: "Play"
    };
  },

  mounted() {
    if (this.bleedImages.length > 1) {
      this.preloadImages();
      this.setSlideshowInterval();
    }
  },

  methods: {
    // Preloads images to avoid the user seeing a white flash when the background image changes.
    preloadImages() {
      this.bleedImages.slice(1, this.bleedImages.length).forEach(url => {
        const image = new Image();
        image.src = url;
        this.preloadedImages.push(image);
      });
    },

    updateImageIndex() {
      if (this.imageIndex < this.bleedImages.length-1) {
        this.imageIndex++;
      } else if (this.imageIndex === this.bleedImages.length-1) {
        this.imageIndex = 0;
      }
    },

    changeBackgroundImage() {
      if (this.isSlideshowActive) {
        this.updateImageIndex();
        const image = this.bleedImages[this.imageIndex];
        this.$refs.hero.style.backgroundImage = `url(${image})`;
      }
    },

    setSlideshowInterval() {
      this.activeSlideshowInterval = setInterval(this.changeBackgroundImage, 1000 * this.bleedBackgroundRotationInSeconds);
    },

    toggleSlideshow() {
      if(!this.isSlideshowActive) {
        this.setSlideshowInterval();
        this.isSlideshowActive = true;
        this.controlsAltText = "Pause";
      } else {
        clearInterval(this.activeSlideshowInterval);
        this.isSlideshowActive = false;
        this.controlsAltText = "Play";
      }
    }
  }
};

export default Hero;
