<script>
export default {

  props: {
    as: String,
    // Had to use className because of warnings that `class` is reserved.
    className: String,
    eventName: String,
    href: String,
    styleObject: [String, Object],
    getTrackingProps: Function
  },

  data() {
    return {
      amplitudeInstance: null,
      subscriptionProps: {}
    };
  },

  mounted() {
    // check if already loaded
    if (window.amplitude && window.amplitude.getInstance()) {
      this.setAmplitudeInstance();
    }
    if (window.globalSubData) {
      const subData = window.globalSubData;
      const planSize = subData.subscription_plan_size ?
        subData.subscription_plan_size[0].toUpperCase() + subData.subscription_plan_size.substring(1) : "";

      this.subscriptionProps = {
        subscription_id: subData.subscription_id,
        "Subscription Type": subData.subscription_type,
        "Plan Size": planSize,
        "Multisub": subData.multisub
      };
    }
    document.addEventListener("amplitude-loaded", this.setAmplitudeInstance);
  },

  beforeDestroy() {
    document.removeEventListener("amplitude-loaded", this.setAmplitudeInstance);
  },

  methods: {
    handleClick(e) {
      this.trackEvent();
      if (this.$listeners.click) {
        this.$listeners.click(e);
      }
    },

    trackEvent() {
      if (this.amplitudeInstance) {
        const defaultProps = {
          Path: window.location.pathname,
          Referrer: window.document.referrer || "None",
          "Logged In": Boolean(window.current_user).toString(),
          ...this.subscriptionProps
        };

        let trackingProps = this.getTrackingProps ? this.getTrackingProps() : {};

        if (window.current_user) {
          this.amplitudeInstance.setUserId(window.current_user.id);
        }

        this.amplitudeInstance.logEvent(this.eventName, Object.assign(defaultProps, trackingProps));
      }
    },

    setAmplitudeInstance() {
      this.amplitudeInstance = window.amplitude.getInstance();
    }
  },

  render(h) {
    return h(
      this.as,
      {
        class: this.className,
        style: this.styleObject,
        attrs: {
          href: this.href
        },
        on: {
          click: this.handleClick
        }
      },
      [this.$slots.default]
    );
  }
};
</script>
