import Vue from "vue";

Vue.config.errorHandler = function(err, vm, info) {
  if (typeof newrelic !== "undefined" && typeof newrelic?.noticeError !== "undefined") {
    newrelic.noticeError(err, {
      vm,
      info
    });
  }

  console.error(err);
};

export default Vue;
