<template>
  <div
    v-cloak
    v-bind:id="carouselId"
    class="content-block-carousel-component display-none display-lg-block">
    <slot name="slides" />

    <slot
      name="nav"
      v-bind:slidesInView="slidesInView"
      v-bind:updateCarousel="updateCarousel"
      v-bind:isLeftMoveable="isLeftMoveable"
      v-bind:isRightMoveable="isRightMoveable" />
  </div>
</template>

<script>

import Vue from "Instances/BarkVue";
import ProductBlock from "CMS/product_block_component/ProductBlock";
let contentBlockCarouselAmplitude = { trackEvent: () => {} };

if (window.AmplitudeTracking) {
  contentBlockCarouselAmplitude = AmplitudeTracking(window);
}

export default {
  props: {
    carouselId: {
      type: String,
      required: true
    },

    slideCount: {
      type: Number,
      require: true
    },

    slidesPerPage: {
      type: Number,
      required: true
    }
  },

  components: {
    ProductBlock
  },

  data() {
    return {
      activePage: 0,
      isLeftMoveable: false,
      isRightMoveable: false,
      pages: null,
      pageCount: null
    };
  },

  computed: {
    slidesInView() {
      const firstNumber = (this.activePage + 1) + (this.activePage * (this.slidesPerPage - 1));
      const endRange = firstNumber + (this.slidesPerPage - 1);
      const secondNumber = endRange < this.slideCount ? endRange : this.slideCount;
      const firstString = firstNumber == this.slideCount ? firstNumber : `${ firstNumber } - ${ secondNumber }`;

      return `${firstString} of ${this.slideCount}`;
    }
  },


  mounted() {
    this.setContentCarouselData();
  },

  methods: {
    setContentCarouselData() {
      const container = document.getElementById(this.carouselId);
      this.pages = Array.from(container.querySelectorAll(`.content-collection-${this.slidesPerPage}-block`));
      this.pageCount = this.pages.length;
      this.isRightMoveable = this.pageCount > 1;

      this.pages.forEach((page, i) => {
        if (i !== this.activePage) {
          page.classList.add("hidden");
        }
      });
    },

    trackNavClick(direction) {
      const eventName = "Content Block Carousel Nav Clicked";
      const props = {
        "carousel id" : this.carouselId,
        "direction" : direction > 0 ? "right" : "left",
        "slides in view": this.slidesInView
      };

      contentBlockCarouselAmplitude.trackEvent(eventName, props);
    },

    updateCarousel(direction) {
      this.trackNavClick(direction);

      if (direction > 0) {
        if (!this.isRightMoveable) return;
      } else {
        if (!this.isLeftMoveable) return;
      }

      this.pages[this.activePage].classList.add("hidden");
      this.activePage = this.activePage + direction;
      this.pages[this.activePage].classList.remove("hidden");
      this.isRightMoveable = this.activePage < this.pageCount - 1;
      this.isLeftMoveable = this.activePage > 0;
    }
  }
};
</script>
